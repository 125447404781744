import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import { SuccessMessage } from './SuccessNotification';
import React, { useState } from 'react';

export default function Form() {
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);

  const [email, setEmail] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();

    const url = 'https://api.airtable.com/v0/appCmcZM66d4g8PHQ/tblyCULPZD69PmjLg';
    const data = {
      records: [
        {
          fields: {
            "Email": email,
            "Business Name": businessName,
            "Country": country,
            "City": city,
            "Date Added": new Date().toISOString().split('T')[0],
          }
        }
      ]
    };

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer patOaOXmO2DpEx5iC.f9b5e16b7ea7341ac2d45f2977419ffc5a1708d8b6a0674c7d7d98a327c4ce33',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {
      setIsSuccess(true);
    } else {
      console.error('Failed to add email to Airtable');
    }
  };
  return (
    <>
    <div className="bg-white px-6 py-32 lg:px-8">
    <div className="mx-auto max-w-3xl text-base leading-7 text-brand-blue">
    <form onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-brand-blue">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-red sm:text-sm sm:leading-6"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-brand-blue">
                Business Name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="street-address"
                  id="street-address"
                  autoComplete="street-address"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-red sm:text-sm sm:leading-6"
                  value={businessName}
                  onChange={e => setBusinessName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-span-full">
              <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-brand-blue">
                Country
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="country"
                  id="country"
                  autoComplete="country"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-red sm:text-sm sm:leading-6"
                  value={country}
                  onChange={e => setCountry(e.target.value)}
                />
              </div>
            </div>
            <div className="col-span-full">
              <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-brand-blue">
                City
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="city"
                  id="city"
                  autoComplete="city"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-red sm:text-sm sm:leading-6"
                  value={city}
                  onChange={e => setCity(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button type="button" className="text-sm font-semibold leading-6 text-brand-blue">
          Cancel
        </button>
        <button
          className="rounded-md bg-brand-red px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-red-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-red"
          type="submit"
        >
          Save
        </button>

      </div>
    </form>
    {isSuccess && <SuccessMessage message="You're a Legend!" />}
    </div>
    </div>
    </>
  )
}
