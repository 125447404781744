import Footer from "./Footer";
import Form from "./Form";
import PageHeader from "./Header";
import LearnMore from "./LearnMore";


const Layout = () => {
  return (
    <div className="bg-brand-gradient-blue-light">
      {
        <>
      <PageHeader />
      <LearnMore />
      <Form />
      <Footer />
      </>
      }
    </div>
  );
}

export default Layout;

