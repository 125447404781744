import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'

export default function LearnMore() {
  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-brand-red">Introducing</p>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-brand-blue sm:text-4xl">Neighbourhood Watchers With Penguin!</h1>
      </div>
    </div>
  )
}
